import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import ProfileForm from "../../Components/Settings/profile";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { useSelector } from "react-redux";
import NotificationService from "../../Services/notification";

const ProfileModal = ({ open, onClose }) => {
  const [profileForm] = Form.useForm();
  const [profileData, setProfileData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const authState = useSelector((state) => state.auth);

  useEffect(() => {
    if (open) {
      fetchProfileData();
    }
  }, [open]);

  const fetchProfileData = async () => {
    try {
        setLoadingData(true);
      const response = await httpClient.get(
        Urls.ACCOUNT_PROFILE.replace("#{accountId}", authState?.accountData?.accountId)
      );
      if (isSuccessStatus(response?.status)) {
        setProfileData(response.data);
        profileForm.setFieldsValue(response.data);
      }
      setLoadingData(false);
    } catch (error) {
      console.error("Error fetching profile data:", error);
      setLoadingData(false);
    }
  };

  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      data.phoneNumber = data.phoneNumber?.replace(/-/g, "");
      const response = await httpClient.put(
        Urls.ACCOUNT_PROFILE.replace("#{accountId}", authState?.accountData?.accountId),
        data
      );
      if (isSuccessStatus(response?.status)) {
        NotificationService.success("Success", "Account details updated successfully");
        onClose();
      }
      setLoading(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      setLoading(false);
    }
  };

  const handleClose=()=>{
    profileForm.resetFields()
    onClose()
  }

  return (
    <Modal title="Account Details" open={open} onCancel={onClose} footer={null}>
      <ProfileForm formObject={profileForm} initialValuesObject={profileData} onFinish={handleSubmit} loading={loading} backToListPage={handleClose} />
    </Modal>
  );
};

export default ProfileModal;
