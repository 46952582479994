import { Button, Form, Input, Space } from "antd";

const ChangePasswordForm = (props) => {
  const { onFinishFailed,backToListPage,loading, onFinish, formObject } = props;

  return (
    <>
      <Form
        className="common-form"
        data-testid='change-password-form'
        form={formObject}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}    
        style={{
          maxWidth: 600,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="password"
          label="Password"
          data-testid='change-password-input'
          className="!mt-3"
          rules={[
            {
              required: true,
              message: "Please enter your password!",
            },
             {
              min: 8,
              message: "Password must be at least 8 characters long!",
            },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
              message: "Password must include at least one alphabet, one number, and one special character.",
            },
          ]}
          hasFeedback
        >
          <Input.Password id="passwordChangePassword" />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={["password"]}
          data-testid='confirm-password-input'
          className="!mb-2"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password id="passwordConfirmPassword" />
        </Form.Item>

        <Form.Item
          className="!mb-0 flex justify-end "
        >
           <Space>
              <Button
                id="backToProfile"
                type="default"
                onClick={() => {
                  backToListPage();
                }}
              >
                Close
              </Button>
              <Button loading={loading} className="submit-btn" id="submitProfile" type="primary" htmlType="submit">
                Submit
              </Button>
            </Space>
        </Form.Item>
      </Form>
    </>
  );
};
export default ChangePasswordForm;
