import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Popconfirm,
  Space,
  Table,
  Tag,
} from "antd";
import ShowForRoleComponent from "../../Services/ShowForRoles";
import { Role } from "../../Constant/Common";
import ShowDescriptionInListing from "../Common/showDescriptionInListing";
import TotalReocordsInListingComponent from "../Common/totalRecordsInListing";
import { getProductIcon, isEmptyData } from "../../Util/commonUtility";
import TableLayout from "../Layout/tableLayout";
import CommonFilterForm from "../Common/commonFilterForm";

const ListProductsComponent = (props) => {
  const {
    dataSource,
    viewProduct,
    productsFilterData,
    viewProductDetails,
    addProduct,
    handlePageChange,
    paging,
    deleteProduct,
    editProduct,
    onProductEnvironmentClick,
    handleSearch,
    handleReset,
  } = props;

  const tableHeaderStyle = {
    backgroundColor: "#c4dcf6",
  };


  const filterFields = [
    {
      name: "SearchText",
      component: (
        <Input className="list-filter-common-style" placeholder="Name" />
      ),
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (code) =>
        <Tag >{code}</Tag>
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) =>
        <Tag >
          <span className="flex align-center gap-2">
            {getProductIcon(type)}
            {type}
          </span>
        </Tag>
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <ShowDescriptionInListing title={text} text={text} />,
    },
    {
      title: "Environments",
      dataIndex: "environments",
      key: "environments",
      render: (text, record) => {
        return (
          <>
            {record?.environments?.map((env, index) => {
              return (
                <Tag
                  id={`environment-${env.id}`}
                  key={index}
                  className={
                    env.isFullAccess
                      ? "common-tag-color"
                      : "common-tag-color-inactive"
                  }
                  onClick={(e) => {
                    onProductEnvironmentClick(e, env?.id, record);
                  }}
                // className="tags"
                // color={colorCodeBasedOnEnvironemnt(env.name.toLowerCase())}
                >
                  <a
                    href={`/product/${env.name}`}
                    onContextMenu={(e) => {
                      onProductEnvironmentClick(e, env?.id, record);
                    }}
                  >
                    {env?.name?.toUpperCase()}
                  </a>
                </Tag>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: "5%",
      render: (text, record) => {
        return (
          <Space>
            <Dropdown overlay={menu(record)} trigger={["click"]}>
              <Button
                className="margin-bottom-zero"
                type="link"
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </Space>
        );
      },
    },
  ];
  const handleMenuClick = (e, record) => {
    switch (e.key) {
      case "productInfo":
        viewProductDetails(record);
        break;
      case "viewProduct":
        viewProduct(record);
        break;
      case "editProduct":
        editProduct(record);
        break;

      default:
        break;
    }
  };

  const menu = (record) => (
    <Menu onClick={(e) => handleMenuClick(e, record)}>
      <Menu.Item key="viewProduct">
        <Space>
          <EyeOutlined id={`viewProduct-${record.id}`} title="Details" />
          Product Details
        </Space>
      </Menu.Item>
      <Menu.Item key="editProduct">
        <ShowForRoleComponent permissions={[Role.ADMIN]}>
          <Space>
            <EditOutlined
              id={`editProduct-${record.id}`}
              title="Edit"
              onClick={() => {
                editProduct(record);
              }}
            />
            Edit Product
          </Space>
        </ShowForRoleComponent>
      </Menu.Item>
      <Menu.Item key="deleteProduct">
        <ShowForRoleComponent permissions={[Role.ADMIN]}>
          <Popconfirm
            title="Are you sure to delete this product ?"
            onConfirm={() => {
              deleteProduct(record);
            }}
          >
            <Space>
              <DeleteOutlined
                title="Delete"
                id={`deleteProduct-${record.id}`}
              />
              Delete product
            </Space>
          </Popconfirm>
        </ShowForRoleComponent>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="px-[2rem] pb-4 py-2">
      <div className="flex align-center justify-between py-2 !pt-2 gap-2">
        <div className="text-[22px]  font-semibold">
          Products
        </div>
        <div className="flex gap-2">
          <ShowForRoleComponent permissions={[Role.ADMIN]}>
            <Button
              id="addProduct"
              type="primary"
              className="common-button !mb-0"
              onClick={() => {
                addProduct();
              }}
              title="Add Product"
              icon={<PlusOutlined />}
            >
              Add Product
            </Button>
          </ShowForRoleComponent>
        </div>
      </div>
      <div>
        <CommonFilterForm
          filterFields={filterFields}
          onSubmit={handleSearch}
          onReset={handleReset}
          initialValues={productsFilterData}
        />
      </div>

      <TableLayout>
        <Table
          prefixCls="list-products"
          className="product-listing"
          components={{
            header: {
              cell: (props) => {
                return <th >{props.children}</th>;
              },
            },
          }}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                viewProduct(record);
              },
              style: {
                cursor: "pointer",
                "&:hover": {
                  background: "black",
                },
              },
            };
          }}
          dataSource={dataSource?.result}
          pagination={
            isEmptyData(dataSource?.result) ? {
              showTotal: (total, range) => (
                <TotalReocordsInListingComponent
                  totalRecords={paging.totalRecords}
                />
              ),
              pageSize: paging.pageSize,
              total: paging.totalPages * paging.pageSize,
              current: paging.pageIndex,
              totalRecords: paging.totalRecords,
              onChange: (page, pageSize) => handlePageChange(page, pageSize),
            } : false
          }
          columns={columns}
          rowKey={"id"}
          size="small"
        />
      </TableLayout>

    </div>
  );
};
export default ListProductsComponent;
