/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import { Menu, Tooltip } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { handleLinkClick } from "../../Util/commonUtility";
import ShowForRoleComponent from "../../Services/ShowForRoles";
import { Role } from "../../Constant/Common";

const SideBarMainLayout = ({ collapsed, setCollapsed, selectedTabId, sidebarItems, onTabChange, showLabel = true }) => {
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState([]);

  const handleMenuClick = (tab, event) => {
    handleLinkClick(event, tab.url, navigate);
    onTabChange(tab.key);
  };

  const handleSubMenuOpen = (keys) => {
    setOpenKeys(keys.length > 0 ? [keys[keys.length - 1]] : []);
  };

  return (
    <div className={`transition-all rounded-lg duration-300 pl-[0.8rem] flex bg-white justify-between ${collapsed ? "w-[5rem]" : "w-[12.8rem]"} main-sidebar-container fixed h-full`}>
      <Menu
        inlineCollapsed={collapsed}
        mode={!collapsed ? "inline" : "vertical"}
        selectedKeys={[selectedTabId]}
        openKeys={openKeys}
        onOpenChange={handleSubMenuOpen}
        className="h-full border-0 bg-transparent mt-2 text-gray-700"
      >
        {sidebarItems.map((tab) => {
          const icons = tab.icon
          const keys = tab.key
          const label = tab.label
          const showLabel = tab.showLabel
          const url = tab.url
          return tab.children ? (
            <Menu.SubMenu key={keys} icon={icons} title={(!collapsed && showLabel) ? label : null}>
              {tab.children.map((child) => {
                const childLabel = child.label
                const childShowLabel = child.showLabel
                const childUrl = child.url 
                const childKey = child.key
                return <Menu.Item key={childKey} >
                  <a href={childUrl} onClick={(e) => handleMenuClick(child, e)}>{!collapsed ? childLabel : childShowLabel}</a>
                </Menu.Item>
              }
              )}
            </Menu.SubMenu>
          ) : (
            label === "Users" ? (
              <ShowForRoleComponent permissions={[Role.ADMIN]} key={keys}>
                <Menu.Item
                  key={keys}
                  title={false}
                  icon={icons}
                  className={!collapsed && "pl-4"}
                >
                  {collapsed ? (
                    <Tooltip title={label} placement="right">
                      <a href={url} className="text-decoration-none hover:text-decoration-none" onClick={(e) => handleMenuClick(tab, e)}></a>
                    </Tooltip>
                  ) : (
                    <a href={url} onClick={(e) => handleMenuClick(tab, e)}>
                      {label}
                    </a>
                  )}
                </Menu.Item>
              </ShowForRoleComponent>
            ) : (
              <Menu.Item title={false} key={keys} icon={icons}>
                {collapsed ? (
                  <Tooltip title={label} placement="right">
                    <a href={url} onClick={(e) => handleMenuClick(tab, e)}></a>
                  </Tooltip>
                ) : (
                  <a href={url} onClick={(e) => handleMenuClick(tab, e)}>{label}</a>
                )}
              </Menu.Item>
            )
          )
        }

        )}
      </Menu>
      <div className="h-full w-[1px] ml-[10px] layout-separator bg-[#eeeff2] relative hover:bg-gray-300 transition-all">
        <button className="absolute expand-toggle-button w-auto z-2 shadow flex align-center hover:!bg-[#1677ff] hover:!text-white -translate-y-1/2 right-[-9px] mt-[2.2rem] bg-white p-1 rounded-full border hover:border-none transition-all" onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <RightOutlined className="text-xs" /> : <LeftOutlined className="text-xs" />}
        </button>
      </div>
    </div>
  );
};

export default SideBarMainLayout;
