import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Row, Col, Form, Input, Space, Switch, Tag, Card } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import TargetCriteria from "../../Containers/TargetCriteria/TargetCriteria";
import { hasInvalidNested } from "../../Util/commonUtility";
import { NOT_ALLOW_WHITESPACE_REGEX } from "../../Constant/regex";
import { maxNameCharacterLength } from "../../Constant/Common";
import { useLocation, useParams } from "react-router-dom";
import FormLayout from "../Layout/formLayout";

const AddProductTargetComponent = (props) => {
  const { backToListPage, addEditTargetData, onFinish, onFinishFailed } = props;
  const [form] = Form.useForm();
  const { id } = useParams();
  const location = useLocation();
  const targetHeading = location.pathname.includes("add") ? "Add" : "Edit";
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  
  const TargetCriteriaFinalValue = (data, json) => {
    form.setFieldValue("targetCondition", data);
    form.setFieldValue("targetConditionJson", JSON.stringify(json));
    const hasInvalidDeepNested = hasInvalidNested(data);
    setDisableSubmitButton(hasInvalidDeepNested);
  };

  useEffect(() => {
    addEditTargetData && form.setFieldValue(addEditTargetData);
  }, [addEditTargetData]);


  return (
    <FormLayout formTitle={`${targetHeading}  Target`}>
      <>
        <Row className="target-row">
          {id && (
            <Col lg={21} md={24}>
              <div className="bread-Crumb-Container">
                <Space className="target-update">
                  {addEditTargetData && addEditTargetData?.updatedBy && (
                    <>
                      Updated By :<Tag>{addEditTargetData.updatedBy}</Tag>
                    </>
                  )}
                  {addEditTargetData && addEditTargetData?.updatedAt && (
                    <>
                      Updated Date :
                      <Tag>
                        {" "}
                        {new Date(addEditTargetData.updatedAt).toDateString()}
                      </Tag>
                    </>
                  )}
                </Space>
              </div>
            </Col>
          )}
        </Row>
        <Form
          initialValues={addEditTargetData}
          form={form}
          className="common-form"
          name="add-product"
          labelCol={{
            span: 5,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item hidden={true} name="id">
            <Input />
          </Form.Item>
          <Card className="deco-card-padding">
            <Row>
              <Col lg={8} sm={24}>
                <Form.Item
                  className="deco-ant-form-item-no-bottom-margin"
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input name here!",
                    },
                    {
                      pattern: NOT_ALLOW_WHITESPACE_REGEX,
                      message: "Whitespace is not allowed in the name!",
                    },
                    {
                      max: maxNameCharacterLength,
                      message: "Input cannot exceed 50 characters!",
                    },
                  ]}
                >
                  <Input id="targetName" className="target-max-width" />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input your description!",
                    },
                  ]}
                >
                  <TextArea id="targetDescription" className="target-max-width" />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24}>
                <Form.Item
                  label="Enabled?"
                  name={"isEnabled"}
                  valuePropName="checked"
                >
                  <Switch id="targetSwitch" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col lg={24} sm={24}>
              <Card className="deco-card-padding">
                <Form.Item
                  label="Target Criteria"
                  name="targetCondition"
                  rules={[
                    {
                      required: true,
                      validator: async (_, datas) => {
                        if (
                          !datas?.equationGroup ||
                          datas?.equationGroup?.length < 1
                        ) {
                          return Promise.reject(
                            new Error("Please add target criteria")
                          );
                        }
                      },
                    },
                  ]}
                >
                  <TargetCriteria
                    initialQuery={addEditTargetData?.queryJson}
                    TargetCriteriaFinalValue={(data, json) => {
                      TargetCriteriaFinalValue(data, json);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please select your query!",
                    },
                  ]}
                  name={"targetConditionJson"}
                ></Form.Item>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Space>
                  <Button
                    id="backToTargetListing"
                    type="default"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => {
                      backToListPage();
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    className="submit-btn"
                    id="submitTarget"
                    type="primary"
                    htmlType="submit"
                    disabled={disableSubmitButton}
                  >
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    </FormLayout>
  );
};

export default AddProductTargetComponent;

