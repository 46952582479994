import {
  ArrowLeftOutlined,
  LeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import TargetCriteria from "../../Containers/TargetCriteria/TargetCriteria";
import {
  fromObjectToKeyValueArray,
  hasInvalidNested,
  isNullOrEmpty,
} from "../../Util/commonUtility";
import { MaskedInput } from "antd-mask-input";
import {
  MASKED_BUILD_VERSION,
  maxNameCharacterLength,
} from "../../Constant/Common";
import NumericInput from "../Common/numericInput";
import dayjs from "dayjs";
import { useLocation, useParams } from "react-router-dom";
import FormLayout from "../Layout/formLayout";
const { Sider } = Layout;
const AddProductBuildRollOutComponent = (props) => {
  const {
    backToListPage,
    editBuildRolloutData,
    onFinish,
    productBuildArray,
    productTargets,
    targetOperators,
    targetAttributeDropdownData,
    showDuplicationPopUp,
    setShowDuplicationPopUp,
    duplicateErrorMessage,
  } = props;
  const [form] = Form.useForm();
  const { id } = useParams();
  const [selectedBuild, setSelectedBuild] = useState(undefined);
  const [selectedBuildName, setSelectedBuildName] = useState(undefined);
  const [selectedTargetIdsArray, setSelectedTargetIdsArray] = useState([]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [metaDataArray, setMetaDataArray] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const buildRolloutHeading = location.pathname.includes("add") ? "Add" : "Edit";

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    if (editBuildRolloutData) {
      const modifiedBuildMeta =
        fromObjectToKeyValueArray(
          editBuildRolloutData?.noUpdateableBuildMeta
        )?.map((a) => ({ ...a, editable: false })) || [];

      const targetIds = editBuildRolloutData.targets?.map((target) => {
        return { value: target.targetId };
      });
      const metaDataArray = editBuildRolloutData?.updateBuildMeta?.map(
        (meta) => {
          const foundAttribute = targetAttributeDropdownData?.find(
            (a) => a.value === meta.key
          );
          if (!foundAttribute) {
            throw new Error(`Attribute with key "${meta.key}" not found`);
          }
          return foundAttribute;
        }
      );
      setSelectedBuild({ ...selectedBuild, modifiedBuildMeta });
      setSelectedTargetIdsArray(targetIds);
      setMetaDataArray(metaDataArray);
      const buildData = productBuildArray?.find(
        (obj) => obj.id === editBuildRolloutData?.buildId
      );
      if (buildData) {
        setSelectedBuildName(`${buildData?.name} (${buildData?.version})`)
      }
      form.setFieldsValue(editBuildRolloutData);
    }
  }, [editBuildRolloutData]);

  const TargetCriteriaFinalValue = (data, json) => {
    form.setFieldValue("targetCriteria", data);
    form.setFieldValue("targetCriteriaJson", JSON.stringify(json));
    const hasInvalidDeepNested = hasInvalidNested(data);
    setDisableSubmitButton(hasInvalidDeepNested);
  };

  const onSelectTarget = (data, name) => {
    setSelectedTargetIdsArray((prevSelectedTargets) => {
      const newArray = [...prevSelectedTargets];
      const existingIndex = newArray.findIndex((item) => item.id === name);
      if (existingIndex !== -1) {
        newArray[existingIndex] = { ...newArray[existingIndex], value: data };
      } else {
        newArray.push({ id: name, value: data });
      }

      return newArray;
    });
  };
  const onRemoveTarget = (targetId) => {
    setSelectedTargetIdsArray((prevSelectedTargets) =>
      prevSelectedTargets.filter((a) => a.value !== targetId)
    );
  };

  const onSelectMetaData = (selected, index) => {
    const selectedAttribute = targetAttributeDropdownData.find(
      (a) => a.value === selected
    );
    setMetaDataArray((prev) => {
      const newMetaDataArray = [...prev];
      newMetaDataArray[index] = {
        ...newMetaDataArray[index],
        ...selectedAttribute,
      };
      return newMetaDataArray;
    });
  };

  const onRemoveMetaData = (index) => {
    setMetaDataArray((prev) => prev.filter((_, i) => i !== index));
  };
  const onSelectProductBuild = (id) => {
    const buildData = productBuildArray.find((obj) => obj.id === id);

    buildData.modifiedBuildMeta = fromObjectToKeyValueArray(
      buildData.buildMeta
    )?.map((a) => ({ ...a, editable: false }));

    setSelectedBuild(buildData);
    setSelectedBuildName(`${buildData?.name} (${buildData?.version})`)
  };

  const onFinishFormError = async () => {
    try {
      await form.validateFields(["targets"]);
      setCustomErrorMessage({});
    } catch {
      setCustomErrorMessage({
        targets: "Please add at least one target criteria",
      });
    }
  };

  const fieldGenerator = (opt) => {
    switch (opt) {
      case "String":
        return <Input placeholder="Please enter string value" />;
      case "Boolean":
        return (
          <Radio.Group>
            <Radio value={true}>True</Radio>
            <Radio value={false}>False</Radio>
          </Radio.Group>
        );
      case "Date":
        return <DatePicker className="react-query-builder-input-string" />;
      case "Number":
        return <NumericInput className="react-query-builder-input-string" />;
      case "Version":
        return (
          <MaskedInput
            mask={MASKED_BUILD_VERSION}
            className="react-query-builder-input-string"
          />
        );
      default:
        return null;
    }
  };

  const handleOk = () => {
    const formattedData = {
      ...editBuildRolloutData,
      AllowForceInsert: true,
    };
    onFinish(formattedData);
    setShowDuplicationPopUp(false);
  };
  const handleCancel = () => {
    setShowDuplicationPopUp(false);
  };
  return (

    <FormLayout formTitle={`${buildRolloutHeading} Release`}>
      <>
        <Form
          initialValues={editBuildRolloutData}
          form={form}
          className="common-form"
          name="add-product"
          onFinish={onFinish}
          onFinishFailed={onFinishFormError}
          autoComplete="off"
          layout="vertical"
        >
          <Modal
            open={showDuplicationPopUp}
            title="Warning: Duplicate Target Detected"
            okText="Proceed and Save"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={(_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            )}
          >
            <p className="text-gray-700 mb-4">
              A target with the same identifier already exists in the release. Do
              you want to proceed with forcefully inserting this release? Please
              note that this action may overwrite the existing target.
            </p>
            <span className="text-sm font-bold mb-2">
              {duplicateErrorMessage}
            </span>
          </Modal>
          <Row>
            {id && (
              <Col>
                {editBuildRolloutData && (
                  <Space className="" direction="horizontal">
                    {editBuildRolloutData.updatedBy && (
                      <>
                        Updated By :<Tag>{editBuildRolloutData.updatedBy}</Tag>
                      </>
                    )}
                    {editBuildRolloutData.updatedAt && (
                      <>
                        Updated Date :
                        <Tag>
                          {new Date(
                            editBuildRolloutData.updatedAt
                          ).toDateString()}
                        </Tag>
                      </>
                    )}
                  </Space>
                )}
              </Col>
            )}
          </Row>
          <Layout style={{ minHeight: "100vh" }}>
            {/* <Row style={{ width: "100%" }} gutter={[10, 10]}>
            <Col lg={6} md={24}> */}
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              onCollapse={toggleCollapsed}
              width={300}
              style={{ background: "#fff" }}
            >
              <Card
                className="deco-card-padding"
                style={{ minHeight: "100vh" }}
                title="Release"
                extra={
                  <>
                    <Button type="ghost" onClick={toggleCollapsed}>
                      {collapsed ? <RightOutlined /> : <LeftOutlined />}
                    </Button>
                  </>
                }
              >
                {collapsed ? "Expand to add/edit release data" : null}
                <Form.Item hidden={true} name="id">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Name"
                  name="name"
                  hidden={collapsed ? true : false}
                  rules={[
                    {
                      required: true,
                      message: "Please input your name!",
                    },
                    {
                      max: maxNameCharacterLength,
                      message: "Input cannot exceed 50 characters!",
                    },
                  ]}
                >
                  <Input id="releaseName" className="build-rollout-max-width" />
                </Form.Item>
                <Form.Item
                  label="Description"
                  name="description"
                  hidden={collapsed ? true : false}
                  rules={[
                    {
                      required: true,
                      message: "Please input your description!",
                    },
                  ]}
                >
                  <TextArea
                    id="releaseDescription"
                    className="build-rollout-max-width"
                  />
                </Form.Item>

                {editBuildRolloutData?.id && (
                  <Form.Item
                    label="Enabled?"
                    name={"isEnabled"}
                    hidden={collapsed ? true : false}
                    valuePropName="checked"
                  >
                    <Switch id="releaseSwitch" />
                  </Form.Item>
                )}
                <Form.Item
                  label="Build"
                  name="buildId"
                  hidden={collapsed ? true : false}
                  rules={[
                    {
                      required: true,
                      message: "Please select build!",
                    },
                  ]}
                >
                  <Select
                    id="selectBuildVersion"
                    className="build-rollout-max-width"
                    onChange={(data) => onSelectProductBuild(data)}
                  >
                    {productBuildArray?.map((build) => (
                      <Select.Option key={build.id} value={build.id}>
                        <Tooltip title={productBuildArray ? `Build Name:  ${build.name} ${`( ${build.version} )`}` : ''}>
                          {build.name} {`( ${build.version} )`}
                        </Tooltip>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Meta Data" hidden={collapsed ? true : false}>
                  <Space className="meta-data-string" size={[1, 1]} wrap>
                    {selectedBuild?.modifiedBuildMeta?.map((a, index) => {
                      return (
                        <Tag key={index}>
                          <Space>
                            {a.key} : {a.value}
                          </Space>
                        </Tag>
                      );
                    })}
                  </Space>
                  {targetAttributeDropdownData && (
                    <Form.List className="form-list" label="Targets" name={"updateBuildMeta"}>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }, index) => (
                            <Card className="deco-card-padding" key={key}>
                              <MinusCircleOutlined
                                style={{
                                  position: "absolute",
                                  top: 16,
                                  right: 16,
                                  fontSize: "16px",
                                  color: "#000",
                                  cursor: "pointer",
                                  zIndex: 999,
                                }}
                                onClick={() => {
                                  onRemoveMetaData(name);
                                  remove(name);
                                }}
                              />
                              <Row>
                                <Col>
                                  <Space
                                    align="baseline"
                                    className="metadata-container"
                                    direction="vertical"
                                    wrap
                                    size={[1, 1]}
                                  >
                                    <Form.Item
                                      {...restField}
                                      name={[name, "key"]}
                                      style={{ marginBottom: "0px" }}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please select target attribute",
                                        },
                                      ]}
                                    >
                                      <Select
                                        wrap
                                        className="react-query-builder-input-string"
                                        onChange={(data) => {
                                          onSelectMetaData(data, name);
                                        }}
                                      >
                                        {targetAttributeDropdownData?.map(
                                          (attribute) => {
                                            return (
                                              <Select.Option
                                                key={attribute.value}
                                                value={attribute.value}
                                                disabled={metaDataArray?.some(
                                                  (a) =>
                                                    a.value === attribute.value
                                                )}
                                              >
                                                {attribute.label}
                                              </Select.Option>
                                            );
                                          }
                                        )}
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      prefixCls="product-build-form-field"
                                      {...restField}
                                      name={[name, "value"]}
                                      style={{ marginBottom: "0px" }}
                                      getValueFromEvent={
                                        metaDataArray[index]?.type === "Date"
                                          ? (e) => e?.format("YYYY-MM-DD")
                                          : undefined
                                      }
                                      getValueProps={
                                        metaDataArray[index]?.type === "Date"
                                          ? (e) => ({ value: e && dayjs(e) })
                                          : undefined
                                      }
                                      rules={[
                                        ({ getFieldValue }) => ({
                                          validator(_, value) {
                                            const fieldValue = getFieldValue([
                                              "updateBuildMeta",
                                              name,
                                              "key",
                                            ]);
                                            if (
                                              fieldValue &&
                                              isNullOrEmpty(value)
                                            ) {
                                              return Promise.reject(
                                                `Please insert value for "${fieldValue}"`
                                              );
                                            } else {
                                              return Promise.resolve();
                                            }
                                          },
                                        }),
                                      ]}
                                    >
                                      {fieldGenerator(
                                        metaDataArray[index]?.type,
                                        index
                                      )}
                                    </Form.Item>
                                  </Space>
                                </Col>
                              </Row>
                            </Card>
                          ))}
                          <Form.Item>
                            <Button
                              id="addMetaDataInRelease"
                              type="dashed"
                              onClick={() => add()}
                              block
                              size="small"
                              className="target-max-width"
                              icon={<PlusOutlined />}
                            >
                              Add Meta data
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  )}
                </Form.Item>
              </Card>
            </Sider>
            {/* </Col>
            <Col lg={18} md={24}> */}
            <Card className="deco-card-padding">
              <Form.Item label="Target Criteria">
                <Form.List
                  name={"targets"}
                  rules={[
                    {
                      required: true,
                      validator: async (_, datas) => {
                        let equationGroup =
                          form.getFieldValue("targetCriteria")?.equationGroup ||
                          [];
                        let condition =
                          equationGroup.length > 0 || (datas && datas.length > 0);

                        if (!condition) {
                          return Promise.reject(new Error(""));
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => {
                        return (
                          <div key={key}>
                            <Row>
                              <Col>
                                <Space align="baseline">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "targetId"]}
                                    className="build-target-max-width deco-ant-form-item-no-bottom-margin"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select target",
                                      },
                                    ]}
                                  >
                                    <Select
                                      className="build-target-max-width deco-ant-form-item-no-bottom-margin"
                                      onChange={(data) => {
                                        onSelectTarget(data, name);
                                      }}
                                    >
                                      {productTargets?.map((build) => {
                                        return (
                                          <Select.Option
                                            key={build.id}
                                            value={build.id}
                                            disabled={selectedTargetIdsArray?.some(
                                              (a) => a.value === build.id
                                            )}
                                          >
                                            {build.name}
                                          </Select.Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    hidden={true}
                                    {...restField}
                                    name={[name, "targetOperator"]}
                                    className="build-target-max-width"
                                    initialValue={
                                      targetOperators &&
                                        targetOperators?.length > 0
                                        ? targetOperators[0].name
                                        : ""
                                    }
                                  >
                                    <Input defaultValue={"AND"} value={"AND"} />
                                    {/* <Select
                                options={targetOperators?.map(
                                  (build, index) => ({
                                    label: build.label,
                                    value: build.name,
                                  })
                                )}
                                className="build-target-max-width"
                              ></Select> */}
                                  </Form.Item>
                                  <MinusCircleOutlined
                                    onClick={async () => {
                                      await onRemoveTarget(
                                        form.getFieldValue([
                                          "targets",
                                          name,
                                          "targetId",
                                        ])
                                      );

                                      remove(name);
                                    }}
                                  />
                                </Space>
                              </Col>
                            </Row>
                            <div
                              style={{ textAlign: "center", maxWidth: "300px" }}
                            >
                              And
                            </div>
                          </div>
                        );
                      })}

                      <Form.Item>
                        <Button
                          id="addTargetInRelease"
                          type="dashed"
                          onClick={() => add()}
                          block
                          size="small"
                          className="target-max-width"
                          icon={<PlusOutlined />}
                        >
                          Add Target
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <div style={{ textAlign: "center", maxWidth: "300px" }}>And</div>
                <Form.Item
                  name="targetCriteria"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <TargetCriteria
                    initialQuery={editBuildRolloutData?.queryJson}
                    TargetCriteriaFinalValue={(data, json) => {
                      TargetCriteriaFinalValue(data, json);
                    }}
                  />
                </Form.Item>
                <div className="common-error">{customErrorMessage?.targets}</div>
              </Form.Item>
              <Form.Item name={"targetCriteriaJson"}></Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Space>
                  <Button
                    id="backToReleaseListingPage"
                    type="default"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => {
                      backToListPage();
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    className="submit-btn"
                    id="submitRelease"
                    type="primary"
                    htmlType="submit"
                    disabled={disableSubmitButton}
                  >
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </Card>
            {/* </Col>
            <Col xs={24}></Col> */}

            {/* </Row> */}
          </Layout>
        </Form>
      </>
    </FormLayout>
  );
};

export default AddProductBuildRollOutComponent;

