import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Divider, Input, Popconfirm, Select, Space, Table } from "antd";
import React from "react";
import ShowDescriptionInListing from "../Common/showDescriptionInListing";
import ShowForRightAccessComponent from "../../Services/ShowForRightAccess";
import TotalReocordsInListingComponent from "../Common/totalRecordsInListing";
import CommonFilterForm from "../Common/commonFilterForm";
import TableLayout from "../Layout/tableLayout";

const ListTargetProductComponent = (props) => {
  const {
    productTargets,
    paging,
    handlePageChange,
    editProductTarget,
    deleteProductTarget,
    listFilterSubmit,
    resetFilter,
    listFilterData,
  } = props;

  const filterFields = [
    {
      name: "name",
      component: (
        <Input className="list-filter-common-style" placeholder="Name" />
      ),
    },
    {
      name: "description",
      component: (
        <Input className="list-filter-common-style" placeholder="Description" />
      ),
    },
    {
      name: "isEnabled",
      component: (
        <Select
          className="list-filter-common-style select-target-min-width"
          placeholder="Is Enabled?"
        >
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      ),
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <ShowDescriptionInListing title={text} text={text} />,
    },
    // {
    //   title: "Environment Id",
    //   dataIndex: "environmentId",
    //   key: "environmentId",
    //   // render: (version, row) => {
    //   //   return <Tag className="common-tag-color">{version}</Tag>;
    //   // },
    // },
    // {
    //   title: "Product Id",
    //   dataIndex: "productId",
    //   key: "productId",
    // },
    {
      title: "Enabled",
      dataIndex: "isEnabled",
      key: "isEnabled",
      render: (isEnabled, row) => (isEnabled ? "Yes" : "No"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <>
            <Space>
              <ShowForRightAccessComponent>
                <EditOutlined
                  title="Edit"
                  onClick={() => {
                    editProductTarget(record);
                  }}
                />
              </ShowForRightAccessComponent>
              <ShowForRightAccessComponent>
                <Divider type="vertical"></Divider>
                <Popconfirm
                  title="Are you sure to delete this target?"
                  onConfirm={() => {
                    deleteProductTarget(record);
                  }}
                >
                  <DeleteOutlined title="Delete" />
                </Popconfirm>
              </ShowForRightAccessComponent>
            </Space>
          </>
        );
      },
    },
  ];
  return (
    <>
      <CommonFilterForm
        filterFields={filterFields}
        onSubmit={listFilterSubmit}
        onReset={resetFilter}
        initialValues={listFilterData}
      />
      <TableLayout>
        <Table
          className="wid-fix"
          dataSource={productTargets?.result}
          columns={columns}
          size="small"
          rowKey={"id"}
          pagination={{
            showTotal: (total, range) => (
              <TotalReocordsInListingComponent
                totalRecords={paging.totalRecords}
              />
            ),
            pageSize: paging.pageSize,
            total: paging.totalPages * paging.pageSize,
            current: paging.pageIndex,
            totalRecords: paging.totalRecords,
            onChange: (page, pageSize) => handlePageChange(page, pageSize),
          }}
        />
      </TableLayout>
    </>
  );
};

export default ListTargetProductComponent;

