import {
  Button,
  Divider,
  Input,
  Popover,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import { DownloadOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { BUILDDATE_FORMAT } from "../../Constant/Common";
import ShowDescriptionInListing from "../Common/showDescriptionInListing";
import ShowForRightAccessComponent from "../../Services/ShowForRightAccess";
import TotalReocordsInListingComponent from "../Common/totalRecordsInListing";
import { useSelector } from "react-redux";
import CommonFilterForm from "../Common/commonFilterForm";
import TableLayout from "../Layout/tableLayout";

const ListProductBuildComponent = (props) => {
  const {
    productBuilds,
    editProductBuild,
    handlePageChange,
    paging,
    listFilterSubmit,
    resetFilter,
    listFilterData,
  } = props;
  
  const isFullAccess = useSelector(
    (state) => state.product.selectedEnvironment?.isFullAccess
  );

  const filterFields = [
    {
      name: "name",
      component: (
        <Input className="list-filter-common-style" placeholder={"Name"} />
      ),
    },
    // {
    //   name: "description",
    //   component: (
    //     <Input
    //       className="list-filter-common-style"
    //       placeholder={"Description"}
    //     />
    //   ),
    // },
    {
      name: "version",
      component: (
        <Input className="list-filter-common-style" placeholder={"Version"} />
      ),
    },
    {
      name: "isEnabled",
      component: (
        <Select
          allowClear
          className="list-filter-common-style select-target-min-width"
          placeholder={"Is Enabled?"}
        >
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      ),
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <ShowDescriptionInListing title={text} text={text} />,
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      render: (version) => {
        return <Tag className="common-tag-color">{version}</Tag>;
      },
    },
    {
      title: "Published Date",
      dataIndex: "publishedDate",
      key: "publishedDate",
      render: (publishedDate, row) => {
        return moment(publishedDate).format(BUILDDATE_FORMAT);
      },
    },
    
    {
      title: "Download Url",
      dataIndex: "downloadUrl",
      key: "downloadUrl",
      render: (downloadUrl) => (
        <Popover content={downloadUrl} title="Url">
          <a href={downloadUrl}>
            <Button size="small" icon={<DownloadOutlined />}>
              Download
            </Button>
          </a>
        </Popover>
      ),
    },
    {
      title: "CI/CD",
      dataIndex: "cdCiIdentifier",
      key: "cdCiIdentifier",
      render: (cdCiIdentifier) => cdCiIdentifier,
    },
    {
      title: "Enabled",
      dataIndex: "isEnabled",
      key: "isEnabled",
      render: (isEnabled) => (isEnabled ? "Yes" : "No"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <Space>
            <ShowForRightAccessComponent>
              <EditOutlined
                title="Edit"
                onClick={() => {
                  editProductBuild(record);
                }}
              />
            </ShowForRightAccessComponent>
            {/* <Divider type="vertical"></Divider>
            <Popconfirm
              title="Are you sure to delete this access ?"
              onConfirm={() => {
                deleteProductBuild(record);
              }}
            >
              <DeleteOutlined title="Delete" />
            </Popconfirm> */}
          </Space>
        );
      },
    },
  ];
  return (
    <>
      <CommonFilterForm
        filterFields={filterFields}
        onSubmit={listFilterSubmit}
        onReset={resetFilter}
        initialValues={listFilterData}
      />
      <TableLayout>
      <Table
        className="build-listing"
        dataSource={productBuilds?.result}
        pagination={{
          showTotal: (total, range) => (
            <TotalReocordsInListingComponent
              totalRecords={paging.totalRecords}
            />
          ),
          pageSize: paging.pageSize,
          total: paging.totalPages * paging.pageSize,
          current: paging.pageIndex,
          totalRecords: paging.totalRecords,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
        }}
        columns={columns.filter((column) => {
          if (column.title === "Action" && !isFullAccess) {
            return false;
          }
          return true;
        })}
        rowKey={"id"}
        size="small"
      />
      </TableLayout>
    </>
  );
};
export default ListProductBuildComponent;
