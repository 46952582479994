import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import { TARGET_ATTRIBUTE_TYPE } from "../../Constant/DropDown";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { NOT_ALLOW_WHITESPACE_REGEX } from "../../Constant/regex";
import { maxNameCharacterLength } from "../../Constant/Common";
import FormLayout from "../Layout/formLayout";

const AddTargetAttributes = (props) => {
  const { onFinish, onFinishFailed, backToListPage, targetattributeData } =
    props;
  const [form] = Form.useForm();
  const location = useLocation();
  const targetHeading = location.pathname.includes("add") ? "Add" : "Edit";

  useEffect(() => {
    targetattributeData && form.setFieldsValue(targetattributeData);
  }, [targetattributeData, form]);

  return (
    <FormLayout formTitle={`${targetHeading}  Target Attribute`}>
      <Form
        form={form}
        className="common-form"
        name="add-product"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
          marginTop: "20px",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item hidden={true} name="id">
          <Input />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name here!",
            },
            {
              pattern: NOT_ALLOW_WHITESPACE_REGEX,
              message: "Whitespace is not allowed in the name!",
            },
            {
              max: maxNameCharacterLength,
              message: "Input cannot exceed 50 characters!",
            },
          ]}
        >
          <Input id="addTargetAttributeName" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Please input your description!",
            },
          ]}
        >
          <TextArea id="addTargetAttributeDescription" />
        </Form.Item>
        {targetattributeData && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              maxWidth: "100%",
              marginBottom: "10px",
              flex: "1 1 0",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "14px" }}>Type : </p>{" "}
            <div
              style={{
                // border: "1px solid lightgray ",
                width: "67%",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              {!targetattributeData?.isUsed ? (
                <Form.Item
                  label={""}
                  name={"type"}
                  rules={[
                    {
                      required: true,
                      message: "Please select the type!",
                    },
                  ]}
                // hidden={editTargetAttribute?.id ? true : false}
                >
                  <Select
                    id="selectTargetAttributeType"
                    // defaultValue={targetattributeData?.type}
                    // disabled={editTargetAttribute?.id ? true : false}
                    options={TARGET_ATTRIBUTE_TYPE}
                  ></Select>
                </Form.Item>
              ) : (
                <>
                  <p style={{ fontSize: "14px" }}>
                    {targetattributeData.type}
                  </p>
                  <Form.Item
                    hidden={true}
                    name={"type"}
                    rules={[
                      {
                        required: true,
                        message: "Please select the type!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </>
              )}
            </div>
          </div>
        )}
        {!targetattributeData && (
          <Form.Item
            label={"Type"}
            name={"type"}
            rules={[
              {
                required: true,
                message: "Please select the type!",
              },
            ]}
          // hidden={editTargetAttribute?.id ? true : false}
          >
            <Select
              id="selectTargetAttributeType"
              // disabled={editTargetAttribute?.id ? true : false}
              options={TARGET_ATTRIBUTE_TYPE}
            ></Select>
          </Form.Item>
        )}
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Space>
            <Button
              id="backToTargetAttributeListing"
              type="default"
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                backToListPage();
              }}
            >
              Back
            </Button>
            <Button
              id="submitTargetAttribute"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </FormLayout>
  );
};
export default AddTargetAttributes;

