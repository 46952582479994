import {
  ArrowLeftOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Space,
  Switch,
  Tag,
} from "antd";
import { useEffect, useRef, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import {
  addArrayDefaultValueToSchema,
  isNullOrEmpty,
  scrollToErrorOnForm,
} from "../../Util/commonUtility";
import FlagEditor from "./FlagEditor";
import { defaultConfig } from "./defaultConfig";
import { maxNameCharacterLength } from "../../Constant/Common";
import { useLocation } from "react-router-dom";
import FormLayout from "../Layout/formLayout";

const { Sider } = Layout;

const AddProductFeatureFlagComponent = (props) => {
  const {
    id,
    onFinish,
    backToListPage,
    editProductFeatureFlagData,
    setCanSubmit,
  } = props;
  const location = useLocation();
  const [addProductFeatureFlagForm] = Form.useForm();
  const [editorSchema, setEditorSchema] = useState({});
  const [formSchema, setFormSchema] = useState({});
  const [collapsed, setCollapsed] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [validationErrors, setValidationErrors] = useState([]);
  const formContainerRef = useRef(null);
  const formEditorRef = useRef(null);
  const featureHeading = location.pathname.includes("add") ? "Add" : "Edit";

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (
      !editProductFeatureFlagData &&
      !isNullOrEmpty(defaultConfig) &&
      isNullOrEmpty(editorSchema)
    ) {
      setEditorSchema(defaultConfig);
      setFormSchema(defaultConfig);
      addProductFeatureFlagForm.setFieldValue("SchemaConfig", defaultConfig);
    }
  }, [addProductFeatureFlagForm, editProductFeatureFlagData, formSchema]);

  const handleFinish = (values) => {
    if (validationErrors.length > 0) {
      scrollToErrorOnForm(validationErrors.slice(-1)[0].path, formEditorRef);
    }
    onFinish(values);
  };

  const handleFinishFailed = (errorInfo) => {
    if (validationErrors.length > 0) {
      scrollToErrorOnForm(validationErrors.slice(-1)[0].path, formEditorRef);
    }
  };

  const onChangeJSON = (json) => {
    if (json) {
      const generatedJson = addArrayDefaultValueToSchema(json);
      setFormSchema(generatedJson);
      addProductFeatureFlagForm.setFieldValue("SchemaConfig", generatedJson);
    } else {
      setFormSchema(null);
      addProductFeatureFlagForm.setFieldValue("SchemaConfig", null);
    }
  };

  useEffect(() => {
    if (formValues && !isNullOrEmpty(formValues)) {
      addProductFeatureFlagForm.setFieldValue("defaultConfig", formValues);
    }
  }, [addProductFeatureFlagForm, formValues]);

  useEffect(() => {
    if (editProductFeatureFlagData) {
      if (editProductFeatureFlagData.defaultConfig) {
        if (typeof editProductFeatureFlagData.defaultConfig === "string") {
          const configValue = JSON.parse(
            editProductFeatureFlagData?.defaultConfig
          );
          setFormValues(configValue);
        } else {
          setFormValues(editProductFeatureFlagData?.defaultConfig);
        }
      }
      if (editProductFeatureFlagData.schemaConfig) {
        if (typeof editProductFeatureFlagData.schemaConfig === "string") {
          const schemaValue = JSON.parse(
            editProductFeatureFlagData?.schemaConfig
          );
          setEditorSchema(schemaValue);
          setFormSchema(schemaValue);
          addProductFeatureFlagForm.setFieldsValue({
            ...editProductFeatureFlagData,
            SchemaConfig: schemaValue,
          });
        } else {
          setEditorSchema(editProductFeatureFlagData?.schemaConfig);
          setFormSchema(editProductFeatureFlagData?.schemaConfig);
          addProductFeatureFlagForm.setFieldsValue({
            ...editProductFeatureFlagData,
            SchemaConfig: editProductFeatureFlagData?.schemaConfig,
          });
        }
      }
      if (editProductFeatureFlagData.SchemaConfig) {
        if (typeof editProductFeatureFlagData.SchemaConfig === "string") {
          const schemaValue = JSON.parse(
            editProductFeatureFlagData?.SchemaConfig
          );

          setEditorSchema(schemaValue);
          setFormSchema(schemaValue);
          addProductFeatureFlagForm.setFieldsValue({
            ...editProductFeatureFlagData,
            SchemaConfig: schemaValue,
          });
        } else {
          setEditorSchema(editProductFeatureFlagData?.SchemaConfig);
          setFormSchema(editProductFeatureFlagData?.SchemaConfig);
          addProductFeatureFlagForm.setFieldsValue({
            ...editProductFeatureFlagData,
            SchemaConfig: editProductFeatureFlagData?.SchemaConfig,
          });
        }
      }
    }
  }, [addProductFeatureFlagForm, editProductFeatureFlagData]);

  return (
    <FormLayout formTitle={`${featureHeading}  Feature`}>
      <>
        <Form
          initialValues={editProductFeatureFlagData}
          form={addProductFeatureFlagForm}
          className="common-form"
          id="add-featureflag"
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Row>
            {id && (
              <Col>
                {editProductFeatureFlagData && (
                  <Space className="" direction="horizontal">
                    {editProductFeatureFlagData.updatedBy && (
                      <>
                        Updated By :
                        <Tag>{editProductFeatureFlagData.updatedBy}</Tag>
                      </>
                    )}
                    {editProductFeatureFlagData.updatedAt && (
                      <>
                        Updated Date :
                        <Tag>
                          {new Date(
                            editProductFeatureFlagData.updatedAt
                          ).toDateString()}
                        </Tag>
                      </>
                    )}
                  </Space>
                )}
              </Col>
            )}
          </Row>
          <Layout style={{ minHeight: "100vh" }}>
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              onCollapse={toggleCollapsed}
              width={300}
              style={{ background: "#fff" }}
            >
              <Card
                className="deco-card-padding"
                style={{ minHeight: "100vh" }}
                title="Feature Flag"
                extra={
                  <>
                    <Button type="ghost" onClick={toggleCollapsed}>
                      {collapsed ? <RightOutlined /> : <LeftOutlined />}
                    </Button>
                  </>
                }
              >
                {collapsed ? "Expand to add/edit feature flag data" : null}
                <Form.Item hidden={true} name="id" id="id">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Name"
                  name="name"
                  id="name"
                  hidden={collapsed ? true : false}
                  rules={[
                    {
                      required: true,
                      message: "Please input your name!",
                    },
                    {
                      max: maxNameCharacterLength,
                      message: "Input cannot exceed 50 characters!",
                    },
                  ]}
                >
                  <Input id="releaseName" className="build-rollout-max-width" />
                </Form.Item>
                <Form.Item
                  label="Description"
                  name="description"
                  id="description"
                  hidden={collapsed ? true : false}
                  rules={[
                    {
                      required: true,
                      message: "Please input your description!",
                    },
                    {
                      max: 200,
                      message: "Description must be at least 200 characters",
                    },
                  ]}
                >
                  <TextArea
                    id="releaseDescription"
                    className="build-rollout-max-width"
                  />
                </Form.Item>
                {id ? (
                  <Form.Item
                    name="code"
                    hidden={collapsed ? true : false}
                    id="code"
                  >
                    <Space>
                      Code :
                      <Tag>{addProductFeatureFlagForm.getFieldValue("code")}</Tag>
                    </Space>
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Code"
                    name="code"
                    id="code"
                    hidden={collapsed ? true : false}
                    rules={[
                      {
                        required: true,
                        message: "Please input code!",
                      },
                      { min: 6, message: "Code must consist of 6 characters." },
                      { max: 6, message: "Code must consist of 6 characters." },
                    ]}
                  >
                    <Input id="code" className="build-rollout-max-width" />
                  </Form.Item>
                )}
                <Form.Item
                  label="Enabled?"
                  name={"isEnabled"}
                  id="isEnabled"
                  hidden={collapsed ? true : false}
                  valuePropName="checked"
                >
                  <Switch id="releaseSwitch" />
                </Form.Item>
              </Card>
            </Sider>
            <Card className="deco-card-padding">
              <FlagEditor
                editorSchema={editorSchema}
                formSchema={formSchema}
                onChangeJSON={onChangeJSON}
                formValues={formValues}
                setFormValues={setFormValues}
                setCanSubmit={setCanSubmit}
                formContainerRef={formContainerRef}
                formEditorRef={formEditorRef}
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
                mode="code"
              />
              <Form.Item name={"defaultConfig"} hidden={true} id="defaultConfig">
                <Input />
              </Form.Item>

              <Form.Item name={"SchemaConfig"} hidden={true} id="schemaConfig">
                <Input />
              </Form.Item>

              <Form.Item id="buttons">
                <Space className="feature-flag-btn-space">
                  <Button
                    id="backToListingPage"
                    type="default"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => {
                      backToListPage();
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    className="submit-btn"
                    id="submitRelease"
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </Card>
          </Layout>
        </Form>
      </>
    </FormLayout>
  );
};

export default AddProductFeatureFlagComponent;

