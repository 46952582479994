import { Button, Col, Form, Row, Space } from "antd";
import { useEffect } from "react";

const CommonFilterForm = ({
  filterFields,
  onSubmit,
  onReset,
  initialValues = {},
  layout = {},
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      className="filter-form mb-[0.8rem]"
      {...layout}
    >
      <Row className="list-form-gap filter-component border !border-[#eeeff2] bg-white rounded-lg" gutter={16}>
        {filterFields.map((field) => (
          <Col className="" flex={1} key={field.name}>
            <Form.Item
              name={field.name}
              className="deco-ant-form-item-no-bottom-margin"
            >
              {field.component}
            </Form.Item>
          </Col>
        ))}
        <Col flex={1}>
          <Space className="flex justify-end w-full" style={{ marginTop: "-5px" }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button type="default" onClick={onReset}>
              Reset
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default CommonFilterForm;
