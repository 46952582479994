import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import { PRODUCT_TYPE } from "../../Constant/DropDown";
import { maxNameCharacterLength } from "../../Constant/Common";
import FormLayout from "../Layout/formLayout";
const AddProductComponent = (props) => {
  const { onFinish, onFinishFailed, backToListPage, editProductData } = props;
  const [addProductForm] = Form.useForm();
  const productHeading = editProductData ? "Edit" : "Add"
  return (
    <div className="p-[2rem] !py-3">
      <FormLayout formTitle={`${productHeading}  Product`}>
        <Form
          form={addProductForm}
          className="common-form-add-pro"
          name="add-product"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={editProductData}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item hidden={true} name="id">
            <Input />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
              {
                max: maxNameCharacterLength,
                message: "Input cannot exceed 50 characters!",
              },
            ]}
          >
            <Input id="addProductName" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please input your description!",
              },
            ]}
          >
            <TextArea id="addProductDescription" />
          </Form.Item>
          <Form.Item
            label={"Type"}
            name={"type"}
            rules={[
              {
                required: true,
                message: "Please select the type!",
              },
            ]}
            hidden={editProductData?.id ? true : false}
          >
            <Select id="productType" options={PRODUCT_TYPE}></Select>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Space className="btn_box">
              <Button
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  backToListPage();
                }}
              >
                Back
              </Button>
              <Button
                className="submit-btn"
                id="submitProduct"
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>

      </FormLayout>

    </div>
  );
};
export default AddProductComponent;

