
const SideBarContentLayout = ({
  collapsed,
  selectedTabId,
  sidebarItems,
  copyButton,
  extraButton,
  children,
}) => {
  return (
    <div className={`flex flex-col min-h-screen md:min-h-[80vh] lg:min-h-[90vh] transition-all duration-300 ml-${collapsed ? "[5rem]" : "[13rem]"} w-full p-6 mt-2 pl-0 py-0 pe-0`}>
      <div className="flex-1 overflow-scroll main-sidebar-children-container p-3 !pt-2 rounded-lg">
        <div className="flex justify-between py-0 gap-2">
          <div className="text-[22px] font-semibold">
            {extraButton && (
              sidebarItems
                .flatMap(tab =>
                  tab.children
                    ? [tab, ...tab.children]
                    : [tab]
                )
                .find(item => item.key === selectedTabId)?.showLabel
            )}
          </div>
          <div className="flex gap-2">
            <div>{copyButton}</div>
            <div>{extraButton}</div>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default SideBarContentLayout
