import React, { useState } from "react";
import { Modal, Form, Typography } from "antd";
import ChangePasswordForm from "../../Components/Settings/changePassword";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { useSelector } from "react-redux";
import NotificationService from "../../Services/notification";
import { useNavigate } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;

const ChangePasswordModal = ({ open, onClose }) => {
  const navigate = useNavigate()
  const [changePasswordForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const authState = useSelector((state) => state.auth);

  const handleChangePassword = async (data) => {
    try {
      setLoading(true);
      const response = await httpClient.put(
        Urls.ACCOUNT_CHANGEPASSWORD.replace("#{accountId}", authState?.accountData?.accountId),
        data
      );
      if (isSuccessStatus(response?.status)) {
        changePasswordForm.resetFields();
        NotificationService.success("Success", "Password changed successfully");
        
        navigate("/logout");
        onClose();
      }
      setLoading(false);
    } catch (error) {
      console.error("Error changing password:", error);
      setLoading(false);
    }
  };

  const handleClose=()=>{
    changePasswordForm.resetFields()
    onClose()
  }

  return (
    <Modal title="Change Password" open={open} onCancel={onClose} footer={null}>
      <Text>
        <InfoCircleOutlined className="pe-2"/> 
        You will be redirected to the login page after changing your password.
      </Text>
      <ChangePasswordForm formObject={changePasswordForm} loading={loading} onFinish={handleChangePassword} backToListPage={handleClose} />
    </Modal>
  );
};

export default ChangePasswordModal;
