import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  TimePicker,
  Tooltip,
} from "antd";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  debounceForSearchApiCall,
  replaceContentWithPlaceholders,
} from "../../Util/commonUtility";
import { Option } from "antd/es/mentions";
import Loader from "../Common/Loader";
import {
  DAYS_OPTIONS,
  weekDays,
  END_CONDITION,
  FREQUENCY,
  SHOW_AT,
  CONTROL_TYPE,
  DateFormat,
  ContentTypes,
  maxNameCharacterLength,
} from "../../Constant/Common";
import dayjs from "dayjs";
import { useLocation, useParams } from "react-router-dom";
import DynamicForm from "./LanguagePlaceHolder/dynamicForm";
import FormLayout from "../Layout/formLayout";

const AddContentRolloutsComponent = (props) => {
  const {
    backToListPage,
    onFinish,
    productBuildArray,
    targetOperators,
    productTargets,
    editContentRolloutData,
    allContentTemplates,
    onPreviewXaml,
    usedForCopy,
  } = props;

  const [formContentRollout] = Form.useForm();
  const location = useLocation();
  const { id } = useParams();
  const [showDropDownLoading, setShowDropDownLoading] = useState(false);
  const [selectedTargetIdsArray, setSelectedTargetIdsArray] = useState([]);
  const [selectedBuildName, setSelectedBuildName] = useState(undefined);
  const [currentLanguagePlaceholderData, setCurrentLanguagePlaceholderData] =
    useState();
  const contentRolloutHeading = location.pathname.includes("add") ? "Add" : "Edit";

  const [frequency, setFrequency] = useState();
  const [showAt, setShowAt] = useState();
  const [endCondition, setEndCondition] = useState(null);
  const [templateData, setTemplateData] = useState({
    selectedTemplateType: "",
    languages: [],
    templateSchema: "",
    templatePlaceholder: [],
  });

  const initialValueFormat = (data) => {
    if (data.controlType === CONTROL_TYPE.DATE) {
      return dayjs(data.defaultValue);
    }
    return data.defaultValue;
  };

  const [allFormValues, setAllFormValues] = useState();
  const [activeTab, setActiveTab] = useState();

  const setActiveTabValue = (templateData) => {
    const isEnglishLanguageAvailable = templateData.languages?.find(
      (obj) => obj.code === "en"
    );

    const initialActiveTabValue = isEnglishLanguageAvailable
      ? isEnglishLanguageAvailable?.code
      : templateData.languages[0]?.code;

    setActiveTab(initialActiveTabValue);
  };

  useEffect(() => {
    if (templateData) {
      setActiveTabValue(templateData);
    }
  }, [templateData]);

  const setInitialFormValues = useCallback((templateData) => {
    const initialFormValues = templateData.languages.reduce((acc, lang) => {
      const config = {};
      templateData.templatePlaceholder.forEach((field) => {
        config[`${lang.code}_${field.name}`] = initialValueFormat(field);
      });
      acc[lang.code] = config;
      return acc;
    }, {});
    setAllFormValues(initialFormValues);
  }, []);

  useEffect(() => {
    if (!id && !usedForCopy) {
      setInitialFormValues(templateData);
    }
  }, [id, setInitialFormValues, templateData]);

  const formatDefaultValues = useCallback((record, defaultValue) => {
    if (defaultValue === "" || defaultValue === null || !defaultValue) {
      let finalValue = record.defaultValue;
      return finalValue;
    } else {
      let finalValue = defaultValue;

      if (record.controlType === CONTROL_TYPE.COLOR_PICKER) {
        finalValue =
          typeof defaultValue === "string"
            ? defaultValue
            : defaultValue?.toHexString();
      }
      if (record.controlType === CONTROL_TYPE.DATE) {
        finalValue = dayjs(defaultValue).format(DateFormat);
      }
      return finalValue;
    }
  }, []);

  const getConfig = useCallback(
    (valuesSource, isDefault = false) =>
      templateData.languages.reduce((acc, lang) => {
        const config = {};
        templateData.templatePlaceholder.forEach((field) => {
          const value = isDefault
            ? field?.defaultValue
            : valuesSource[`${lang.code}_${field.name}`];
          config[`${lang.code}_${field.name}`] = formatDefaultValues(
            field,
            value
          );
        });
        acc[lang.code] = config;
        return acc;
      }, {}),
    [
      formatDefaultValues,
      templateData.languages,
      templateData.templatePlaceholder,
    ]
  );

  const handleSetCurrentPlaceholderData = useCallback(
    (valueSource) => {
      const formValues = getConfig(valueSource, false);
      const currentFormValues = formValues[activeTab];

      const updatedPlaceholderData = templateData?.templatePlaceholder?.map(
        (placeholder) => {
          const value = currentFormValues[`${activeTab}_${placeholder.name}`];
          return {
            ...placeholder,
            defaultValue: formatDefaultValues(placeholder, value),
          };
        }
      );
      setCurrentLanguagePlaceholderData(updatedPlaceholderData);
    },
    [
      getConfig,
      activeTab,
      templateData?.templatePlaceholder,
      formatDefaultValues,
    ]
  );

  const handleApiCall = (values, TemplateLanguageData) => {
    const formattedDays = values?.days?.join();
    const formattedStartDate = values.startDate
      ? dayjs(values.startDate).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = values.endDate
      ? dayjs(values.endDate).format("YYYY-MM-DD")
      : null;

    const formattedData = {
      id: values.id,
      buildId: values.buildId,
      contentTemplateId: values.contentTemplateId,
      name: values.name,
      isEnable: values.isEnable,
      targets: values.targets,
      frequency: frequency,
      showAt: values.showAt,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      end: values.end,
      numberOfShow: values.numberOfShow,
      days: formattedDays,
      templateType: templateData?.selectedTemplateType,
      ...(values.showAt === SHOW_AT.SET_TIME && {
        showTime: values?.showTime,
      }),
      templateSchema: values.templateSchema,
      templatePlaceholder: values.templatePlaceholder,
      templateData: TemplateLanguageData,
    };
    onFinish(formattedData);
  };

  const getFormattedValuesForLang = (langCode, values) => {
    return templateData.templatePlaceholder.reduce((acc, field) => {
      acc[`${langCode}_${field.name}`] = values[`${langCode}_${field.name}`];
      return acc;
    }, {});
  };

  const handleValueChange = (_, allValues) => {
    const currentLangCode = activeTab;

    // Update the form values for the active language only
    setAllFormValues((prevValues) => ({
      ...prevValues,
      [currentLangCode]: getFormattedValuesForLang(currentLangCode, allValues),
    }));

    handleSetCurrentPlaceholderData(allValues);
  };

  const handleSubmit = (values) => {
    const submitData = templateData.languages.map((lang) => {
      const langValues = allFormValues[lang.code] || {};
      const config = templateData.templatePlaceholder.reduce((acc, field) => {
        acc[field.name] = formatDefaultValues(
          field,
          langValues[`${lang.code}_${field.name}`]
        );
        return acc;
      }, {});
      const placeholderData = templateData?.templatePlaceholder?.map(
        (placeholder) => {
          return {
            ...placeholder,
            defaultValue: formatDefaultValues(
              placeholder,
              langValues[`${lang.code}_${placeholder.name}`]
            ),
          };
        }
      );
      return {
        langName: lang.name,
        langCode: lang.code,
        config,
        content: replaceContentWithPlaceholders(
          templateData.templateSchema,
          placeholderData
        )?.replacedContent,
      };
    });
    handleApiCall(values, submitData);
  };
  const handleFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };

  const onSelectTarget = (targetId, name) => {
    setSelectedTargetIdsArray((prev) => {
      const updatedArray = [...prev];
      const existingIndex = updatedArray.findIndex((item) => item.id === name);
      if (existingIndex !== -1) {
        updatedArray[existingIndex].value = targetId;
      } else {
        updatedArray.push({ id: name, value: targetId });
      }
      return updatedArray;
    });
  };

  const onRemoveTarget = (targetId) => {
    setSelectedTargetIdsArray((prev) =>
      prev.filter((target) => target.value !== targetId)
    );
  };

  const onSelectTemplate = (templateId) => {
    const template = allContentTemplates.find((obj) => obj.id === templateId);
    formContentRollout.setFieldValue("contentTemplateId", template?.id);
    formContentRollout.setFieldValue(
      "templateSchema",
      template?.templateSchema
    );
    formContentRollout.setFieldValue(
      "templatePlaceholder",
      template.templatePlaceholder
    );
    const templateDataValue = {
      selectedTemplateType: template.templateType,
      languages: template.languages || [],
      templatePlaceholder: template.templatePlaceholder || [],
      templateSchema: template.templateSchema || "",
    };
    setTemplateData(templateDataValue);
    setCurrentLanguagePlaceholderData(template.templatePlaceholder);
    setInitialFormValues(templateDataValue);
    setActiveTabValue(templateDataValue);
  };

  const onSearchTemplate = async (data) => {
    setShowDropDownLoading(true);
    setShowDropDownLoading(false);
  };

  const handleFrequencyChange = (value) => {
    setFrequency(value);
    if (frequency !== value) {
      formContentRollout.setFieldValue("days", null);
    }
  };
  const handleShowAtChange = (value) => {
    formContentRollout.setFieldValue("showTime", null);
    setShowAt(value);
  };
  const handleEndConditionChange = (value) => setEndCondition(value);

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const onSelectProductBuild = (id) => {
    const buildData = productBuildArray.find((obj) => obj.id === id);
    setSelectedBuildName(`${buildData?.name} (${buildData?.version})`)
  };

  useEffect(() => {
    // const currentFormValues = formContentRollout.getFieldsValue();
    const currentConfigData = allFormValues ? allFormValues[activeTab] : {};
    const currentFormValues = activeTab ? allFormValues[activeTab] : {};
    if (allFormValues) {
      formContentRollout.setFieldsValue({
        ...currentFormValues,
        ...currentConfigData,
      });
      const updatedPlaceholderData = templateData?.templatePlaceholder?.map(
        (placeholder) => {
          const value = currentFormValues[`${activeTab}_${placeholder.name}`];
          return {
            ...placeholder,
            defaultValue: formatDefaultValues(placeholder, value),
          };
        }
      );
      setCurrentLanguagePlaceholderData(updatedPlaceholderData);

    }
  }, [
    activeTab,
    allFormValues,
    formContentRollout,
    formatDefaultValues,
    templateData,
    templateData?.templatePlaceholder,
  ]);

  useEffect(() => {
    const editTemplateDataValueFormat = (record, defaultValue) => {
      if (record.controlType === CONTROL_TYPE.DATE) {
        return dayjs(defaultValue);
      }

      return defaultValue;
    };
    if (editContentRolloutData) {
      const editedTemplateData = editContentRolloutData?.templateData;
      const parsedTemplateData =
        typeof editedTemplateData === "string"
          ? JSON.parse(editedTemplateData)
          : editedTemplateData;
      const selectedTemplateData = allContentTemplates?.find(
        (obj) => obj.id === editContentRolloutData.contentTemplateId
      );
      //Performing a set operation for a rollout ensures that if only one language was there when template  initially created, editing the template to add more languages won't affect the existing rollout.
      const templateDataLanguageSet = new Set(
        parsedTemplateData?.map((item) => item.langCode)
      );
      const filterTemplateDataLanguages =
        selectedTemplateData?.languages.filter((item) =>
          templateDataLanguageSet.has(item.code)
        );
      const editedTemplateSchema = editContentRolloutData?.templateSchema;
      const templateSchema =
        editContentRolloutData?.templateType === ContentTypes.JSON
          ? editedTemplateSchema
          : editedTemplateSchema;
      setTemplateData({
        selectedTemplateType: editContentRolloutData?.templateType,
        languages: filterTemplateDataLanguages || [],
        templatePlaceholder: editContentRolloutData?.templatePlaceHolder || [],
        templateSchema: templateSchema || "",
      });

      const templateDataFormat = parsedTemplateData?.reduce((acc, item) => {
        acc[item?.langCode] = item.config;
        return acc;
      }, {});

      const finalFormattedData = filterTemplateDataLanguages?.reduce(
        (acc, lang) => {
          const config = {};

          const currentLangTemplateDataValue = templateDataFormat[lang.code];
          editContentRolloutData?.templatePlaceHolder?.forEach((field) => {
            config[`${lang.code}_${field.name}`] = editTemplateDataValueFormat(
              field,
              currentLangTemplateDataValue[field.name]
            );
          });
          acc[lang.code] = config;
          return acc;
        },
        {}
      );
      setAllFormValues(finalFormattedData);
      setEndCondition(editContentRolloutData.end);
      setFrequency(editContentRolloutData.frequency);
      setShowAt(editContentRolloutData.showAt);

      formContentRollout.setFieldsValue({
        ...editContentRolloutData,
        templateData: editContentRolloutData.templateData || "[]",
        frequency: editContentRolloutData.frequency,
        showAt: editContentRolloutData.showAt || "",
        days: editContentRolloutData?.days?.split(","),
        startDate: editContentRolloutData.startDate
          ? dayjs(editContentRolloutData.startDate)
          : null,
        endDate: editContentRolloutData.endDate
          ? dayjs(editContentRolloutData.endDate)
          : null,
        end: editContentRolloutData.end,
        buildId: editContentRolloutData.buildId,
        numberOfShow: editContentRolloutData.numberOfShow,
        templatePlaceholder: selectedTemplateData?.templatePlaceholder || [],
      });
      const buildData = productBuildArray?.find(
        (obj) => obj.id === editContentRolloutData?.buildId
      );
      if (buildData) {
        setSelectedBuildName(`${buildData?.name} (${buildData?.version})`)
      }
    }
  }, [allContentTemplates, editContentRolloutData, formContentRollout]);

  return (
    <FormLayout formTitle={`${contentRolloutHeading} Content Rollout`}>
      <>
        <Form
          form={formContentRollout}
          className="form-container"
          name="add-product"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          layout="vertical"
          onFinish={handleSubmit}
          onFinishFailed={handleFinishFailed}
          onValuesChange={handleValueChange}
        >
          <Row>
            {id && (
              <Col>
                {editContentRolloutData && (
                  <Space direction="horizontal">
                    {editContentRolloutData.updatedBy && (
                      <>
                        Updated By :
                        <Tag className="tag">
                          {editContentRolloutData.updatedBy}
                        </Tag>
                      </>
                    )}
                    {editContentRolloutData.updatedAt && (
                      <>
                        Updated Date :
                        <Tag className="tag">
                          {new Date(
                            editContentRolloutData.updatedAt
                          ).toDateString()}
                        </Tag>
                      </>
                    )}
                  </Space>
                )}
              </Col>
            )}
          </Row>
          <Layout className="content-rollout-container">
            <Row gutter={[16, 16]} justify="space-between">
              <Col xs={24} sm={24} md={12} lg={12}>
                <Card className="card-container deco-card-padding content-rollout-card-min-height rollout-form">
                  <div className="rollout-grid-layout-two-col">
                    <Form.Item
                      label="Select Template"
                      name="contentTemplateId"
                      rules={[
                        {
                          required: true,
                          message: "Please select the content template!",
                        },
                      ]}
                      hidden={id}
                    >
                      <Select
                        id="templateId"
                        onChange={(data) => onSelectTemplate(data)}
                        onSearch={debounceForSearchApiCall(onSearchTemplate)}
                        notFoundContent={
                          showDropDownLoading ? <Loader /> : <Empty />
                        }
                      >
                        {allContentTemplates?.map((template) => (
                          <Option key={template.id} value={template.id}>
                            {template.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item hidden={true} name="id" id="ContentRolloutId">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      hidden={true}
                      name="templateType"
                      id="templateType"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Name"
                      name={"name"}
                      rules={[
                        {
                          required: true,
                          message: "Please enter content rollout name!",
                        },
                        {
                          max: maxNameCharacterLength,
                          message: "Input cannot exceed 50 characters!",
                        },
                      ]}
                    >
                      <Input id="ContentRolloutName" />
                    </Form.Item>
                    <Form.Item hidden name="templateSchema" />{" "}
                    <Form.Item hidden name="templatePlaceholder" />{" "}

                    <Form.Item
                      label="Select Build"
                      name="buildId"
                      rules={[
                        {
                          required: true,
                          message: "Please select build!",
                        },
                      ]}
                    >
                      <Select
                        id="selectBuildVersion"
                        className="build-rollout-max-width"
                        onChange={(data) => onSelectProductBuild(data)}
                      >
                        {productBuildArray?.map((build, index) => (
                          <Select.Option key={build.id} value={build.id}>
                            <Tooltip title={productBuildArray ? `Build Name:  ${build.name} ${`( ${build.version} )`}` : ''}>
                              {build.name} {`( ${build.version} )`}
                            </Tooltip>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="rollout-grid-layout">
                    <Form.Item name="frequency" label="Frequency">
                      <Select onChange={handleFrequencyChange}>
                        {DAYS_OPTIONS.map((options) => (
                          <Select.Option
                            key={options.label}
                            value={options.value}
                          >
                            {options.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    {frequency === FREQUENCY.DAILY && (
                      <>
                        <Form.Item
                          rules={[
                            {
                              required: frequency,
                              message: "Please select the Show At",
                            },
                          ]}
                          name="showAt"
                          label="Show At"
                        >
                          <Select onChange={handleShowAtChange}>
                            <Select.Option value={SHOW_AT.SET_TIME}>
                              Set Time
                            </Select.Option>
                            <Select.Option value={SHOW_AT.SYSTEM_START_UP}>
                              System Startup
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        {showAt === SHOW_AT.SET_TIME && (
                          <Form.Item
                            rules={[{ required: showAt }]}
                            name="showTime"
                            label="Set Time"
                            getValueFromEvent={(e) =>
                              e ? dayjs(e).format("HH:mm:ss") : null
                            }
                            getValueProps={(e) => {
                              const timeValue = e ? dayjs(e, "HH:mm:ss") : null;
                              return {
                                value: timeValue,
                              };
                            }}
                          >
                            <TimePicker format="HH:mm:ss" />
                          </Form.Item>
                        )}
                      </>
                    )}

                    {frequency === FREQUENCY.WEEKLY && (
                      <>
                        <Form.Item
                          name="days"
                          label="Days"
                          rules={[{ required: frequency }]}
                        >
                          <Select
                            allowClear
                            mode="multiple"
                            placeholder="Select days"
                            options={weekDays}
                            tagRender={({ label, value }) => {
                              return <Tag>{label}</Tag>;
                            }}
                            maxTagCount={1}
                            maxTagPlaceholder={(omittedValues) => {
                              const count = omittedValues.length;
                              return `+${count}`;
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              required: frequency,
                              message: "Please select the Show At",
                            },
                          ]}
                          name="showAt"
                          label="Show At"
                        >
                          <Select onChange={handleShowAtChange}>
                            <Select.Option value={SHOW_AT.SET_TIME}>
                              Set Time
                            </Select.Option>
                            <Select.Option value={SHOW_AT.SYSTEM_START_UP}>
                              System Startup
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        {showAt === SHOW_AT.SET_TIME && (
                          <Form.Item
                            rules={[{ required: showAt }]}
                            name="showTime"
                            label="Set Time"
                            getValueFromEvent={(e) =>
                              e ? dayjs(e).format("HH:mm:ss") : null
                            }
                            getValueProps={(e) => {
                              const timeValue = e ? dayjs(e, "HH:mm:ss") : null;
                              return {
                                value: timeValue,
                              };
                            }}
                          >
                            <TimePicker />
                          </Form.Item>
                        )}
                      </>
                    )}

                    {frequency === FREQUENCY.MONTHLY && (
                      <>
                        <Form.Item
                          name="days"
                          label="Days"
                          rules={[{ required: frequency }]}
                        >
                          <Select
                            allowClear
                            mode="multiple"
                            placeholder="Select days"
                            options={Array.from({ length: 31 }, (_, day) => {
                              const index = day + 1;
                              return {
                                label: index?.toString(),
                                value: index?.toString(),
                              };
                            })}
                            tagRender={({ label, value }) => {
                              return <Tag>{label}</Tag>;
                            }}
                            maxTagCount={4}
                            maxTagPlaceholder={(omittedValues) => {
                              const count = omittedValues.length;
                              return `+${count}`;
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              required: frequency,
                              message: "Please select the Show At",
                            },
                          ]}
                          name="showAt"
                          label="Show At"
                        >
                          <Select onChange={handleShowAtChange}>
                            <Select.Option value={SHOW_AT.SET_TIME}>
                              Set Time
                            </Select.Option>
                            <Select.Option value={SHOW_AT.SYSTEM_START_UP}>
                              System Startup
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        {showAt === SHOW_AT.SET_TIME && (
                          <Form.Item
                            rules={[{ required: showAt }]}
                            name="showTime"
                            label="Set Time"
                            getValueFromEvent={(e) =>
                              e ? dayjs(e).format("HH:mm:ss") : null
                            }
                            getValueProps={(e) => {
                              const timeValue = e ? dayjs(e, "HH:mm:ss") : null;
                              return {
                                value: timeValue,
                              };
                            }}
                          >
                            <TimePicker />
                          </Form.Item>
                        )}
                      </>
                    )}
                  </div>

                  {/* Rollout Duration */}
                  <div className="rollout-grid-layout">
                    <Form.Item
                      getValueFromEvent={(e) => e?.format("YYYY-MM-DD")}
                      getValueProps={(e) => ({ value: e && dayjs(e) })}
                      name="startDate"
                      label="Start Date"
                    >
                      <DatePicker disabledDate={disabledDate} />
                    </Form.Item>

                    <Form.Item name="end" label="End Condition">
                      <Select onChange={handleEndConditionChange}>
                        <Select.Option value={END_CONDITION.SET_DATE}>
                          Set Date
                        </Select.Option>
                        <Select.Option value={END_CONDITION.AFTER_N_SHOW}>
                          After N Shows
                        </Select.Option>
                        <Select.Option value={END_CONDITION.ENDLESS}>
                          Endless
                        </Select.Option>
                      </Select>
                    </Form.Item>

                    {endCondition === END_CONDITION.SET_DATE && (
                      <Form.Item
                        rules={[
                          { required: endCondition },
                          ({ getFieldValue }) => ({
                            validator: async (_, value) => {
                              try {
                                const startDate = getFieldValue("startDate");
                                const startDateValue = startDate
                                  ? dayjs(startDate)
                                  : null;
                                const endDateValue = value ? dayjs(value) : null;

                                if (!startDateValue || !endDateValue) {
                                  return Promise.resolve();
                                }

                                if (!endDateValue.isAfter(startDateValue)) {
                                  return Promise.reject(
                                    "Rollout end date and time must be after rollout start date and time!!"
                                  );
                                }

                                return Promise.resolve();
                              } catch (error) {
                                return Promise.reject(error.message);
                              }
                            },
                          }),
                        ]}
                        name="endDate"
                        label="End Date"
                        getValueFromEvent={(e) => e?.format("YYYY-MM-DD")}
                        getValueProps={(e) => ({ value: e && dayjs(e) })}
                      >
                        <DatePicker disabledDate={disabledDate} />
                      </Form.Item>
                    )}

                    {endCondition === END_CONDITION.AFTER_N_SHOW && (
                      <Form.Item
                        rules={[
                          { required: endCondition },
                          {
                            pattern: /^[1-9]\d*$/,
                            message:
                              "Please input a valid integer without decimal points!",
                          },
                        ]}
                        name="numberOfShow"
                        label="Number of Shows"
                      >
                        <Input type="number" min={1} />
                      </Form.Item>
                    )}
                    {endCondition === END_CONDITION.ENDLESS && (
                      <Form.Item
                        hidden
                        name="end"
                        rules={[{ required: endCondition }]}
                      >
                        <Input type="number" value={null} hidden min={1} />
                      </Form.Item>
                    )}
                  </div>

                  <Form.Item
                    label="Enabled?"
                    name="isEnable"
                    valuePropName="checked"
                  >
                    <Switch id="ContentRolloutSwitch" />
                  </Form.Item>
                  <Form.Item required label="Target Criteria">
                    <Form.List
                      name={"targets"}
                      rules={[
                        {
                          required: true,
                          validator: async (_, data) => {
                            let equationGroup =
                              formContentRollout.getFieldValue("targetCriteria")
                                ?.equationGroup || [];
                            let condition =
                              equationGroup.length > 0 ||
                              (data && data.length > 0);

                            if (!condition) {
                              return Promise.reject(
                                new Error(
                                  "Please add at least one target attribute for rollout"
                                )
                              );
                            }
                          },
                        },
                      ]}
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          <div className="rollout-grid-layout-two-col">
                            {fields.map(({ key, name, ...restField }) => (
                              <Space.Compact block>
                                <Form.Item
                                  {...restField}
                                  name={[name, "targetId"]}
                                  className=""
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select target",
                                    },
                                  ]}
                                >
                                  <Select
                                    className="select-target-min-width"
                                    onChange={(data) => {
                                      onSelectTarget(data, name);
                                    }}
                                  >
                                    {productTargets?.map((build, index) => {
                                      return (
                                        <Select.Option
                                          key={build.id}
                                          value={build.id}
                                          disabled={selectedTargetIdsArray.some(
                                            (a) => a.value === build.id
                                          )}
                                        >
                                          {build.name}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "targetOperator"]}
                                  className=""
                                  initialValue={
                                    targetOperators && targetOperators?.length > 0
                                      ? targetOperators[0].name
                                      : ""
                                  }
                                >
                                  <Select
                                    options={targetOperators?.map(
                                      (build, index) => ({
                                        label: build.label,
                                        value: build.name,
                                      })
                                    )}
                                    className="select-min-width"
                                  ></Select>
                                </Form.Item>

                                <Button
                                  type="primary"
                                  onClick={async () => {
                                    await onRemoveTarget(
                                      formContentRollout.getFieldValue([
                                        "targets",
                                        name,
                                        "targetId",
                                      ])
                                    );

                                    remove(name);
                                  }}
                                  icon={<DeleteOutlined />}
                                ></Button>
                              </Space.Compact>
                            ))}
                          </div>
                          <Row>
                            <Col span={6}>
                              <Form.Item
                                name={"targetCriteria"}
                                prefixCls="target-criteria"
                              >
                                <Button
                                  id="addTargetInRelease"
                                  type="dashed"
                                  onClick={() => add()}
                                  block
                                  size="small"
                                  icon={<PlusOutlined />}
                                  className={
                                    errors?.length > 0 ? "button-danger" : ""
                                  }
                                >
                                  Add Target
                                </Button>

                                <Form.ErrorList errors={errors} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Card className="card-container deco-card-padding content-rollout-card-min-height overflow-scroll h-[67vh]">
                  <>
                    {templateData.languages.length > 0 && (
                      <DynamicForm
                        templateData={templateData}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        onPreviewXaml={onPreviewXaml}
                        currentLanguagePlaceholderData={
                          currentLanguagePlaceholderData
                        }
                        formContentRollout={formContentRollout}
                        allFormValues={allFormValues}
                        setAllFormValues={setAllFormValues}
                      />
                    )}
                  </>
                </Card>
              </Col>
            </Row>

            <div className="button-container">
              <Button
                id="ContentRolloutBackButton"
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  backToListPage();
                }}
                className="back-button"
              >
                Back
              </Button>
              <Button
                className="submit-button"
                id="ContentRolloutSubmitButton"
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </div>
          </Layout>
        </Form>
      </>
    </FormLayout>
  );
};

export default AddContentRolloutsComponent;

